<template>
  <div class="page">

    <div class="data-table-container">
      <div class="tools-bar">
        <el-button-group>
          <el-button type="primary" @click="() => $router.push('/post-info')">新增</el-button>
          <el-button @click="load()">刷新</el-button>
        </el-button-group>
        <table-search-simple :table-data="tableDataAll" v-model="tableData" :fields="['title']"/>
      </div>
      <el-table class="table"
                :data="tableData"
                @row-click="({id}) => $router.push('/post-info?id='+id)"
                highlight-current-row>
        <el-table-column align="center" label="ID" width="95" prop="id"/>
        <el-table-column label="用户">
          <template slot-scope="scope">
            <xid-link :xid="'user-' + scope.row.id"
                      :name="scope.row.user.nickname || scope.row.user.nickname"/>
          </template>
        </el-table-column>
        <el-table-column label="标题" prop="title"/>
        <el-table-column label="时间" width="160" prop="datetime_created" sortable/>
        <el-table-column label="浏览量" width="90" prop="stat_visit" sortable/>
        <el-table-column label="评论数" width="90" prop="stat_comment" sortable/>
        <el-table-column label="点赞数" width="90" prop="stat_like" sortable/>
        <el-table-column label="推荐" width="90">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_recommend"></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import XidLink from "@/comps/xid-link";
export default {
  components: {XidLink},
  data() {
    return {
      loading: false,
      tableData: [],
      tableDataAll: [],
      page: 1,
      total: 1,
    }
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      let data = await this.$api.request('/post/list_simple', {page: this.page})
      this.tableData = data
      this.tableDataAll = data
      window.scrollTo(0, 0)
    },
    onClickRow({id}) {
      this.$router.push({name: 'post-editor', query: {id}})
    }
  }
}
</script>
